import React, { BaseSyntheticEvent } from 'react';
import './Hexagon.css';
import Button from './Button';

interface IDiceNumberProps {
  number: number;
  dice: { [key: number]: number };
  setDice: React.Dispatch<React.SetStateAction<{ [key: number]: number }>>;
  max: number | undefined;
  min: number | undefined;
}

function Hexagon({ number, setDice, dice, max, min }: IDiceNumberProps) {
  const counter = dice[number];

  function handleClick(e: BaseSyntheticEvent) {
    const button = e.target.id;
    if (button === 'add') setDice({ ...dice, [number]: counter + 1 });
    else if (button === 'subtract' && counter > 0)
      setDice({ ...dice, [number]: counter - 1 });
  }

  function setStreakColor() {
    if (max !== undefined && max === counter) return '#ef233c';
    if (min !== undefined && min === counter) return '#caf0f8';
    return '';
  }

  return (
    <div className='hex variables'>
      <div className='hex-background' style={{ backgroundColor: setStreakColor() }}>
        <h1>{number}</h1>
        <Button handleClick={handleClick} sign={'-'} disabled={counter <= 0} />
        <h2 className='counter-header'>{counter}</h2>
        <Button handleClick={handleClick} sign={'+'} disabled={false} />
      </div>
    </div>
  );
}

export default Hexagon;