import { useState } from 'react';
import './App.css';
import Hexagon from './Components/Hexagon';
import Dice from './Constants/Dice';

function App() {
  const [dice, setDice] = useState<{ [key: number]: number }>(Dice);
  const maxFreq = Math.max(...Object.values(dice));
  const minFreq = Math.min(...Object.values(dice));
  const max = maxFreq > 4 ? maxFreq : undefined;
  const min = maxFreq > 4 ? minFreq : undefined;

  return (
    <>
      <header>
        <h1>Catan Stats</h1>
      </header>
      <div className='main-container'>
        <main className='numbers-container'>
          {Object.keys(dice).map((key) => (
            <Hexagon
              key={key}
              number={+key}
              dice={dice}
              setDice={setDice}
              max={max}
              min={min}
            />
          ))}
        </main>
      </div>
      <footer>Made by Guillem Sardà Parreu ©</footer>
    </>
  )
    ;
}

export default App;
