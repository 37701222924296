import { BaseSyntheticEvent } from 'react';
import './Button.css';

interface ButtonProps {
  handleClick: (e: BaseSyntheticEvent) => void;
  sign: '+' | '-';
  disabled: boolean;
}

function Button({ handleClick, sign, disabled }: ButtonProps) {
  return (
    <button
      type='button'
      id={sign === '+' ? 'add' : 'subtract'}
      className='counter-button'
      onClick={handleClick}
      disabled={disabled}
      style={{[sign === '+' ? 'right' : 'left']: '0px'}}
    >
      {sign}
    </button>
  );
}

export default Button;